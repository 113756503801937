import React from 'react';
import BodyStyles from './BodyStyles';
import Helmet from 'react-helmet';

const NoMatch = () => (
  <div>
    <Helmet>
      <title>Dennis Richard - Software Engineer - Error page</title>
      <meta
        name='description'
        content="Hi I'm Dennis, a software engineer. This is my error page."
      />
    </Helmet>
    <BodyStyles>
      <div className='outter-content'>
        <h1>Error: 404</h1>
        <br />
        <p>Sorry this page doesn't exists</p>
        <p>I'll have my minions look at this.</p>
      </div>
    </BodyStyles>
  </div>
);

export default NoMatch;
