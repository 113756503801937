import React from 'react';
import BodyStyles from './BodyStyles';
import Helmet from 'react-helmet';

const Work = () => (
  <div>
    <Helmet>
      <title>Dennis Richard - Software Engineer - Work Experience page</title>
      <meta
        name='description'
        content="Hi I'm Dennis, a software engineer. This is my Work experience page."
      />
    </Helmet>
    <BodyStyles>
      <div className='outter-content'>
        <h1>Work Experience</h1>
        <br />
        <p>
          Professionally, I am a Software engineer at Major League Baseball. I
          do things like
        </p>
        <ul>
          <li>
            Architect tests and its infrastructure for Major and Minor Leagues.
          </li>
          <li>
            Modernize test infrastructure to achieve maximum coverage over front
            end apps.
          </li>
          <li>Build front end solutions for new and existing features.</li>
          <li>
            Enhance and scale Front end apps to achive stake holders vision.
          </li>
        </ul>
      </div>
    </BodyStyles>
  </div>
);

export default Work;
