import React from 'react';
import { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './components/body/Home';
import About from './components/body/About';
import Work from './components/body/Work';
import NoMatch from './components/body/NoMatch';
import NavigationBar from './components/NavigationBar';
import Contact from './components/body/Contact';

function App() {
  let [toggleHamburger, setToggleHamburger] = useState(true);

  return (
    <React.Fragment>
      <NavigationBar
        changeToggleHamburger={(toggleHamburger) =>
          setToggleHamburger(toggleHamburger)
        }
      />
      {toggleHamburger && (
        <Router>
          <Switch>
            <Route exact path='/' component={Home} />
            <Route path='/work' component={Work} />
            <Route path='/about' component={About} />
            <Route path='/contact' component={Contact} />
            <Route component={NoMatch} />
          </Switch>
        </Router>
      )}
    </React.Fragment>
  );
}

export default App;
