import React from 'react';
import BodyStyles from './BodyStyles';
import Helmet from 'react-helmet';

const About = () => (
  <div>
    <Helmet>
      <title>Dennis Richard - Software Engineer - About me page</title>
      <meta
        name='description'
        content="Hi I'm Dennis, a software engineer. This is my about me page."
      />
    </Helmet>
    <BodyStyles>
      <div className='outter-content'>
        <h1>Hello There!</h1>
        <br />
        <p>
          I am a seasoned software engineer who can help you create beautiful
          and modern websites.
        </p>
        <p>
          My work is engineered to use the latest tech stack and with highly
          asthetic design.
        </p>
        <p>
          Setting work aside, I am a loving father, husband and son. And I live
          in New York.
        </p>
      </div>
    </BodyStyles>
  </div>
);

export default About;
