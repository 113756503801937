import React from 'react';
import Helmet from 'react-helmet';
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import BodyStyles from './BodyStyles';

const FormStyles = styled.div`
min-height: 90vh;
display: flex;
align-items: center;
justify-content: center;

.outter-content {
    font-family: OCR A Std, monospace;
    font-weight: bold;
    width: 1000px;
    padding 0 5vw;
    h1 {
        text-align: center;
        font-size: xx-large;
    }
}

.success-msg {
  color: #356b1e;
  padding: 10px 15px;
}

.err-msg {
  color: #c21f4d;
  padding: 10px 15px;
}
`;

const Contact = (props) => {
  let [name, setName] = useState('');
  let [email, setEmail] = useState('');
  let [message, setMessage] = useState('');
  let [disabled, setDisabled] = useState(false);
  let [emailSent, setEmailSent] = useState(null);

  let handleSubmit = (event) => {
    event.preventDefault();
    setDisabled(true);
    setEmailSent(true);
  };

  return (
    <div>
      <Helmet>
        <title>Dennis Richard - Software Engineer - Contact me page</title>
        <meta
          name='description'
          content="Hi I'm Dennis, a software engineer. This is my contact page."
        />
      </Helmet>
      <FormStyles>
        <div className='outter-content'>
          <h1>Lets talk!</h1>
          <br />
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label htmlFor='full-name'>Full Name</Form.Label>
              <Form.Control
                id='full-name'
                name='name'
                type='text'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label htmlFor='email'>Email</Form.Label>
              <Form.Control
                id='email'
                name='email'
                type='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label htmlFor='email'>Message</Form.Label>
              <Form.Control
                id='messsage'
                name='message'
                as='textarea'
                row='3'
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Form.Group>

            <Button
              className='d-inline-block'
              variant='secondary'
              type='submit'
              disabled={disabled}
            >
              Send
            </Button>
            {emailSent === true && (
              <p className='d-inline success-msg'>Email Sent</p>
            )}
            {emailSent === false && (
              <p className='d-inline err-msg'>Email Not Sent</p>
            )}
          </Form>
        </div>
      </FormStyles>
    </div>
  );
};

export default Contact;
